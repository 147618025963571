import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"no-gutters":"","justify":"end","align-content":"start"}},[(_vm.isIntern || _vm.isMaitreOeuvre)?_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.createDevis}},[_vm._v("Créer le devis")]):_vm._e()],1),(_vm.dossier.devis.length > 0)?_c(VDataIterator,{attrs:{"items":_vm.dossier.devis},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VCard,{attrs:{"to":'/devis/' + item.id}},[_c(VCardTitle,{staticClass:"primary--text"},[_vm._v(_vm._s(item.name != null ? item.name : "Devis"))]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[(item.created_by)?_c('span',{staticClass:"grey--text font-italic"},[_vm._v("Créé par : "+_vm._s(item.created_by.first_name)+" "+_vm._s(item.created_by.last_name))]):_vm._e(),_c('br'),_c('span',{staticClass:"grey--text font-italic"},[_vm._v("Modifié le : "+_vm._s(_vm.getReadableDate(item.date_modification)))]),_c('br'),_c('span',{staticClass:"grey--text font-italic"},[_vm._v("Créé le : "+_vm._s(_vm.getReadableDate(item.date_creation)))])]),_c(VCol,{attrs:{"cols":"4"}},[_c('span',{staticClass:"blue--text"},[_vm._v("Montant des travaux")]),_c('br'),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(item.total_ttc)+" €")])])],1)],1)],1)]}}],null,false,320808556)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }