import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Importer manuellement ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Importer le document manuellement")])],1),_c(VCardText,{staticClass:"pa-4"},[_c(VFileInput,{attrs:{"label":"Fichier"},model:{value:(_vm.documentFile),callback:function ($$v) {_vm.documentFile=$$v},expression:"documentFile"}})],1),_c(VCardActions,[_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary","disabled":_vm.uploading},on:{"click":_vm.updateDocument}},[_vm._v("Modifier")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }