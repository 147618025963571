import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('span',{staticClass:"body-1"},[_vm._v(" Numéro d'arrêté CTM ")]),_c('br'),(_vm.selected_dossier.numero_arrete_ctm)?_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.selected_dossier.numero_arrete_ctm)+" ")]):_c('span',{staticClass:"body-2"},[_vm._v(" Non défini ")])]),_c(VCol,{attrs:{"cols":"1"}},[_c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Modifier le numéro d'arrêté CTM")])],1),_c(VCardText,[_c(VTextField,{attrs:{"label":"Numéro d'arrêté ctm"},model:{value:(_vm.numeroArreteCTM),callback:function ($$v) {_vm.numeroArreteCTM=$$v},expression:"numeroArreteCTM"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.updateNumeroArreteCTM}},[_vm._v("Modifier")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }