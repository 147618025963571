<template>
  <div class="px-4 pt-4 pb-0">
    <h2 class="primary--text" v-if="!finances_only">BASE DOCUMENTAIRE</h2>
    <h2 class="primary--text" v-else>APPELS DE FONDS</h2>
    <v-dialog v-model="signingDialog" max-width="800">
      <v-card>
        <v-app-bar color="primary">
          <v-toolbar-title class="white--text">Signer directement</v-toolbar-title>
        </v-app-bar>
        <div v-for="signature in signatures" :key="signature.id">
          <div class="d-flex justify-center align-center">
            <h2>Signature de : {{ signature.signe_par }}</h2>
          </div>
          <v-row no-gutters justify="center" align="center">
            <canvas class="mt-4" :ref="signature.code" :id="signature.code"
                    :height="signature.signature_size[0]"
                    :width="signature.signature_size[1]"></canvas>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="grey" class="white--text" @click="clearSigningPad">Nettoyer</v-btn>
          <v-btn color="primary" class="white--text" :disabled="signing_directly" @click="signDocumentDirectly">Signer
          </v-btn>
          <v-progress-circular v-if="signing_directly" indeterminate color="primary"></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!loading">
      <v-row class="mt-2" :key="modeldoc.id" v-for="modeldoc in filtered_modeldocs">
        <v-col cols="6">
          <p>{{ modeldoc.name }}</p>
        </v-col>
        <p>
          <GenerateDocumentForm @reload="$emit('reload')" :modelDoc="modeldoc" :dossier="dossier"/>
        </p>
      </v-row>
    </div>
    <v-row v-else align-content="center" justify="center">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <h3 class="primary--text">Documents générés</h3>
    <v-row class="mt-2" :key="document.id" v-for="document in filtered_dossiers">
      <v-col cols="12">
        <p>
          {{ document.model_doc.name }}<br>
          <span class="grey--text font-italic">Généré(e) le {{ getFormattedDate(document.date_creation) }}</span>
          <span class="grey--text font-italic" v-if="document.date_envoi_signature !== null"><br>Demande de signature envoyé le {{
              getFormattedDate(document.date_envoi_signature)
            }}</span><br>
          <span class="grey--text font-italic"
                v-if="document.date_signature !== null">Signé le {{
              getFormattedDate(document.date_signature)
            }}</span>
        </p>
      </v-col>
      <p>
        <a v-if="document.document_id !== null && document.document_id !== ''"
           class="primary--text mx-2" target="_blank"
           :href="'https://docs.google.com/document/d/' + document.document_id + '/edit'">Voir le document</a>
        <v-btn v-if="document.document_id !== null && document.document_id !== ''"
               @click="downloadAsPdf(document)" color="primary" text>Télécharger en PDF
        </v-btn>
        <v-btn text :color="running?'grey':'primary'" :disabled="running" class="mx-2"
               @click="deleteDocument(document.id)">Supprimer
        </v-btn>
        <v-btn v-if="document.signed_file === null" text color="primary" @click="startManualSignature(document)">
          Signer directement
        </v-btn>
        <ReplaceDocument @reload="$emit('reload')" :document="document"/>
        <v-btn text
               v-if="document.signature_link === null && document.signed_file === null"
               :color="running?'grey':'primary'"
               :disabled="running" class="mx-2"
               @click="startSigningDocument(document)">
          Signer via YouSign
        </v-btn>
        <v-btn text
               v-else-if="document.signature_link !== null"
               color="primary"
               class="mx-2"
               target="_blank"
               :href="yousign_webapp_link + document.signature_link">
          Voir la procédure de signature
        </v-btn>
        <v-btn v-if="document.signed_file" target="_blank" text color="primary" class="mx-2"
               :href="document.signed_file">Voir le
          document signé
        </v-btn>
      </p>
    </v-row>
    <v-row v-if="generating" align-content="center" justify="center">
      <v-progress-circular indeterminate></v-progress-circular>
      <p class="font-italic text-center">Génération en cours</p>
    </v-row>
  </div>
</template>
<script>
import {getFormattedDate} from "@/util";
import * as config from "@/config.json"
import SignaturePad from "signature_pad";
import GenerateDocumentForm from "@/views/GenerateDocumentForm.vue";
import ReplaceDocument from "@/views/ReplaceDocument.vue";

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'BaseDocumentaire',
  components: {ReplaceDocument, GenerateDocumentForm},
  props: {
    dossier: {},
    finances_only: Boolean,
  },
  async mounted() {
    await this.loadModelDocs();
  },
  data() {
    return {
      loading: false,
      generating: false,
      running: false,
      signing_directly: false,
      signingDialog: false,
      selected_document: null,
      signatures: [],
      yousign_webapp_link: config.MODE === "production" ? 'https://webapp.yousign.com' : 'https://staging-app.yousign.com'
    };
  },
  computed: {
    modeldocs() {
      return this.$store.getters["modeldocs/getModelDocs"];
    },
    filtered_modeldocs() {
      let usedModelDocIds = this.dossier.documents.map((document) => document.model_doc.id);
      return this.modeldocs.filter(modeldoc => usedModelDocIds.indexOf(modeldoc.id) === -1)
          .filter(modeldoc => this.finances_only === true ? modeldoc.name.toLowerCase().indexOf("appel de fonds") !== -1 : true);
    },
    filtered_dossiers() {
      return this.dossier.documents.filter(document => this.finances_only === true ? document.model_doc.name.toLowerCase().indexOf("appel de fonds") !== -1 : true);
    }
  },
  methods: {
    async loadModelDocs() {
      this.loading = true;
      await this.$store.dispatch("modeldocs/fetchModelDocs");
      this.loading = false;
    },
    getFormattedDate,
    async deleteDocument(id) {
      this.running = true;
      await this.$store.dispatch('documents/deleteDocument', {id: id});
      this.running = false;
      this.$emit('reload');
    },
    async createDocument(modelDocId) {
      this.generating = true;
      await this.$store.dispatch("documents/postDocument", {model_doc: modelDocId, dossier: this.dossier.id});
      this.generating = false;
      this.$emit("reload");
    },
    async downloadAsPdf(documentModel) {
      this.running = true;
      await this.$store.dispatch("documents/downloadAsPdf", documentModel);
      this.running = false;
    },
    async startSigningDocument(document) {
      if (!this.dossier.beneficiaires[0].contact.email || (!this.dossier.beneficiaires[0].contact.tel_mobile)) {
        this.$store.dispatch("alerts/announceWarning", {
          title: "Informations incomplètes",
          message: "Attention ! Vous ne pouvez utiliser la signature via YouSign que si le numéro de téléphone et " +
              "l'adresse email sont définis dans la fiche."
        });
        return;
      }
      this.running = true;
      await this.$store.dispatch("documents/startDocumentSigning", document);
      this.running = false;
      this.$emit("reload");
    },
    startManualSignature(doc) {
      if (!doc?.model_doc?.signature_page || !doc?.model_doc?.signature_position) {
        this.$store.dispatch("annonce/annonceWarning", "L'emplacement de signature n'est pas défini pour ce document.")
        return;
      }
      this.signingDialog = true;
      this.selected_document = doc;
      let that = this;
      console.log(this.selected_document);
      this.signatures = this.selected_document?.model_doc?.signatures.map(function (signature) {
        return {
          ...signature,
          code: that.slugify(signature.signe_par),
          signature_size: that.getSize(signature),
          pad: null
        };
      })
      this.$nextTick(async function () {
        await delay(50);
        for (let i = 0; i < that.signatures.length; i++) {
            let signature = that.signatures[i]
            that.signatures[i].pad = new SignaturePad(that.$refs[signature.code][0]);
          }
      })
    },
    clearSigningPad() {
      for (let i = 0; i < this.signatures.length; i++) {
            this.signatures[i].pad.clear();
      }
    },
    slugify(str) {
      return str
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');
    },
    async signDocumentDirectly() {
      this.signing_directly = true;
      this.signatures = this.signatures.map((signature) => ({...signature, data: signature.pad.toDataURL()}))
      let payload = {document: this.selected_document.id, signatures: this.signatures};
      if (this.signatures.length === 0) {
        await this.$store.dispatch("annonce/annonceWarning", "Aucune signature configurée pour ce modèle, opération annulée.")
        this.signing_directly = false;
        this.signingDialog = false;
      }
      await this.$store.dispatch("documents/signDirectly", payload)
      this.signing_directly = false;
      this.signingDialog = false;
      this.selected_document = null;
      this.$emit("reload");
    },
    getSize(signature) {
      if (!signature) {
        return [400, 400];
      }
      let position_text = signature.signature_position.split(",")
      let position = position_text.map((p) => parseInt(p));
      if (position.find((p) => isNaN(p)) !== undefined) {
        return [400, 400];
      }
      return [position[0] - position[2], position[1] - position[3]];
    }
  }
}
</script>
<style scoped>
canvas {
  border: darkgrey 1px solid;
}
</style>