<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn :icon="icon" :color="color" text v-on="on" v-bind="attrs">
        <v-icon>{{operation === "update" ? 'mdi-pencil' : 'mdi-plus'}}</v-icon>
        <span v-if="!icon">{{ operation === 'update' ? 'Modifier le règlement' : "Ajouter un règlement" }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar :color="color">
        <v-toolbar-title class="white--text">{{ operation === 'update' ? 'Modifier le règlement' : "Ajouter un règlement" }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="reglementForm">
          <v-text-field label="Montant" type="number" v-model="reglementMontant" step="0.01"></v-text-field>
          <date-field label="Date de règlement" v-model="reglementDate"></date-field>
          <v-select label="Statut" :disabled="logged_user.group !== 'Administrateur'" :items="statuses" item-text="name"
                    item-value="value" v-model="reglementStatus"></v-select>
          <v-select v-if="isEncaissement" label="Provenance" :items="provenances"
                    v-model="reglementProvenance"></v-select>
          <v-select v-else label="Entreprise" :items="entreprises" item-value="id" item-text="name"
                    v-model="reglementEntreprise"
          ></v-select>
          <date-field :rules="[notNullNorEmpty]" v-if="!isEncaissement" v-model="dateFacture"
                      label="Date de facture"></date-field>
          <v-text-field :rules="[notNullNorEmpty]" v-if="!isEncaissement" v-model="referenceFacture"
                        label="Référence de la facture"></v-text-field>
          <v-file-input :rules="[notNullNorEmptyIfCreating]" v-model="reglementFile"
                        :label="isEncaissement ? 'Justificatif' : 'Facture'"></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-progress-circular :color="color" indeterminate v-if="creating"></v-progress-circular>
        <v-btn v-if="operation === 'update'" :disabled="creating" :color="color" @click="updateReglement">Modifier le règlement</v-btn>
        <v-btn v-else :disabled="creating" :color="color" @click="createReglement">Créer le règlement</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>
import DateField from "@/components/base/DateField.vue";
import {ReglementRepository} from "@/repository/reglement";
import {PROVENANCES} from "@/constants/Financement";
import {AuthMixin} from "@/mixins/AuthMixin";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'ReglementForm',
  components: {DateField},
  mixins: [AuthMixin, ValidationRulesMixin],
  props: {
    saisie: Object,
    operation: {
      type: String,
      default: "create",
    },
    reglement: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    if (this.$store.getters["entreprises/fetch_status"] === "INITIAL") {
      await this.$store.dispatch("entreprises/fetchEntreprises");
    }
  },
  data() {
    return {
        reglementMontant: this.operation === "update" ? this.reglement?.montant : null,
        reglementDate: this.operation === "update" ? this.reglement?.date_reglement : null,
        reglementProvenance: this.operation ==="update" ? this.reglement?.provenance : null,
        reglementEntreprise: this.operation ==="update" ? this.reglement?.entreprise: null,
        reglementStatus: this.operation ==="update" ? this.reglement?.status : this.saisie.type === "Encaissement" ? "waiting_payment" : "waiting",
        dateFacture: this.operation ==="update" ? this.reglement.date_facture : null,
        referenceFacture: this.operation ==="update" ? this.reglement.reference_facture : null,
        reglementFile: null,
        creating: false,
        dialog: false,
      };
  },
  computed: {
    isEncaissement() {
      return this.saisie !== null ? this.saisie.type === "Encaissement" : false;
    },
    color() {
      return this.isEncaissement ? "primary" : "secondary";
    },
    entreprises() {
      return this.$store.getters["entreprises/entreprises"];
    },
    provenances() {
      return PROVENANCES;
    },
    statuses() {
      return [
        {value: "waiting_payment", name: "En attente d'encaissement"},
        {value: "waiting", name: "En attente de paiement"},
        {value: "valid", name: "Validé"},
        {value: "check", name: "A vérifier"},
        {value: "paid", name: "Payé"}
      ]
    },
  },
  methods: {
    notNullNorEmptyIfCreating(value) {
      if(this.operation === "update") return true;
      return value !== '' && value !== null || 'Ne doit pas être vide';
    },
    async createReglement() {
      if (this.$refs.reglementForm.validate()) {
        this.creating = true;
        try {
          let repository = new ReglementRepository();
          let data = {
            saisie: this.saisie.id,
            montant: this.reglementMontant,
            date_reglement: this.reglementDate,
            status: this.reglementStatus,
            provenance: this.reglementProvenance,
            entreprise: this.reglementEntreprise,
            reference_facture: this.referenceFacture,
            date_facture: this.dateFacture,
            file: this.reglementFile,
          }
          let result = await repository.createWithFile(data);
          if (result) {
            this.$emit("reload");
            this.$store.dispatch("annonce/annonceSuccess", "Règlement créé !")
          }

        } finally {
          this.creating = false;
          this.dialog = false;
        }
      }
    },
    async updateReglement() {
      if (this.$refs.reglementForm.validate()) {
        this.creating = true;
        try {
          let repository = new ReglementRepository();
          let data = {
            id: this.reglement.id,
            saisie: this.saisie !== null ? this.saisie.id : this.reglement.saisie,
            montant: this.reglementMontant,
            date_reglement: this.reglementDate,
            status: this.reglementStatus,
            provenance: this.reglementProvenance,
            entreprise: this.reglementEntreprise,
            reference_facture: this.referenceFacture,
            date_facture: this.dateFacture,
            file: this.reglementFile,
          }
          let result = await repository.updateWithFile(data);
          if (result) {
            this.$emit("reload");
            this.$store.dispatch("annonce/annonceSuccess", "Règlement mis à jour !")
          }

        } finally {
          this.creating = false;
          this.dialog = false;
        }
      }
    },
  },

}
</script>