<template>
  <v-row>
    <v-col cols="10">
            <span class="body-1">
              Numéro BPI
            </span><br>
      <span v-if="selectedDossier !== null && selectedDossier.numero_bpi !== null" class="body-2">
        {{ selectedDossier.numero_bpi }}
      </span>

      <span v-else class="body-2">
        Non défini
      </span>
    </v-col>
    <v-col cols="1">
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Modifier le numéro BPI</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field label="Numéro BPI"
                          v-model="numeroBpi"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateNumeroBpi">Modifier</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'StatutPret',
  props: {
    selectedDossier: Object,
  },
  data() {
    return {
      numeroBpi: this.selectedDossier?.numero_bpi,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async updateNumeroBpi() {
      let result = await this.$store.dispatch("dossier/patchDossier", {id: this.selectedDossier.id, numero_bpi: this.numeroBpi});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Numéro BPI mis à jour.");
        this.dialog = false;
      }
      this.dialog = false;
    }
  },
  computed: {
  }
}
</script>
<style>
</style>