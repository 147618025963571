import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-2",attrs:{"text":"","color":"primary"},on:{"click":_vm.loadUnfilledFields}},'v-btn',attrs,false),on),[_vm._v("Générer")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Générer le document")])],1),_c(VCardText,{staticClass:"pt-2"},[(_vm.loading_unfilled)?_c('div',[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}}),_vm._v(" Chargement des champs non complets... ")],1):(_vm.unfilled_fields.length > 0)?_c('div',[_c('p',{staticClass:"orange--text font-weight-bold"},[_vm._v("Les champs suivants sont potentiellement non complets et sont requis pour ce document.")]),_c('ul',_vm._l((_vm.unfilled_fields),function(field,index){return _c('li',{key:index},[_vm._v(_vm._s(field))])}),0)]):_vm._e(),_c('h3',{staticClass:"primary--text"},[_vm._v("Champs à remplir")]),(_vm.completed_fields.length === 0)?_c('div',{staticClass:"font-italic"},[_vm._v("Pas de champ unique à remplir.")]):_vm._e(),_vm._l((_vm.completed_fields),function(field){return _c('div',{key:field.id},[_c(VTextField,{attrs:{"label":field.name},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)})],2),_c(VCardActions,[_c(VBtn,{staticClass:"mx-2",attrs:{"disabled":_vm.generating,"color":_vm.generating ? 'grey' :'primary'},on:{"click":function($event){return _vm.createDocument(_vm.modelDoc.id)}}},[_vm._v("Générer")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }