<template>
  <div class="pa-4">
    <h2 class="primary--text">Informations complémentaires</h2>
    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <template>
            <thead>
            <tr>
              <th>Statut</th>
              <th>Date</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="2" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </td>
            </tr>
            <tr v-for="passage in passages" :key="passage.id">
              <td>{{ passage.statut_name }}</td>
              <td>{{ getFormattedDate(passage.date) }}</td>
              <td>
                <v-tooltip top color="white">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn icon color="red" v-on="on" v-bind="attrs" @click="deletePassage(passage.id)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span class="red--text">Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                <v-select label="Statut" v-model="statutPassage" :items="statuts" item-value="id"
                          item-text="name"></v-select>
              </td>
              <td>
                <v-menu v-model="menu_date_passage" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                        :close-on-click="true">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-on="on" v-bind="attrs" label="Date"
                                  :value="getFormattedDate(datePassage) || ''"></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker v-model="datePassage"></v-date-picker>
                    <v-card-actions>
                      <v-btn @click="menu_date_passage = false" text color="primary">Fermer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </td>
              <td>
                <v-tooltip top color="white">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn @click="createPassage" v-on="on" v-bind="attrs" color="primary" icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span class="primary--text">Créer</span>
                </v-tooltip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <InstructeurDEAL :selected_dossier="selected_dossier"/>
        <NumeroArrete :selected_dossier="selected_dossier"/>
        <NumeroArreteCTM :selected_dossier="selected_dossier"/>
        <DateArrete :selected-dossier="selected_dossier" />
        <AnneeArrete :selected_dossier="selected_dossier"/>
        <DatePaiementEnqueteur :selected-dossier="selected_dossier"/>
        <RetourDEAL :selected-dossier="selected_dossier"/>
        <ControleurDEAL :selected-dossier="selected_dossier"/>
        <EtudeTechnique :selected-dossier="selected_dossier"/>
        <ConducteurTravaux :selected-dossier="selected_dossier"/>
        <StatutPret :selected-dossier="selected_dossier"/>
        <NumeroBPI :selected-dossier="selected_dossier"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {PassageRepository} from "@/repository/passage";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import DateArrete from "@/components/dossiers/DateArrete";
import DatePaiementEnqueteur from "@/components/dossiers/DatePaiementEnqueteur";
import RetourDEAL from "@/components/dossiers/RetourDEAL";
import ControleurDEAL from "@/components/dossiers/ControleurDEAL";
import EtudeTechnique from "@/components/dossiers/EtudeTechnique";
import ConducteurTravaux from "@/components/dossiers/ConducteurTravaux";
import InstructeurDEAL from "@/components/dossiers/InstructeurDEAL.vue";
import NumeroArrete from "@/components/dossiers/NumeroArrete.vue";
import StatutPret from "@/components/dossiers/StatutPret.vue";
import NumeroArreteCTM from "@/components/dossiers/NumeroArreteCTM.vue";
import NumeroBPI from "@/components/dossiers/NumeroBPI.vue";
import AnneeArrete from "@/components/dossiers/AnneeArrete.vue";

export default {
  name: 'DossierInformations',
  components: {
    AnneeArrete,
    NumeroBPI,
    NumeroArreteCTM,
    StatutPret,
    NumeroArrete,
    InstructeurDEAL,
    ConducteurTravaux, EtudeTechnique, ControleurDEAL, RetourDEAL, DatePaiementEnqueteur, DateArrete},
  mixins: [DateUtilMixin],
  async mounted() {
    await this.loadPassages();
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
  },
  data() {
    return {
      repository: new PassageRepository(),
      passages: [],
      loading: true,
      datePassage: null,
      statutPassage: null,
      menu_date_passage: false,
      numeroArreteDialog: false,
      numeroArrete: null,
    }
  },
  watch: {
    selected_dossier() {
      console.log("RELOADED");
    }
  },
  computed: {
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    }
  },
  methods: {
    async loadPassages() {
      this.passages = await this.repository.fetchByDossierId(this.selected_dossier.id);
      this.passages = this.passages.sort((a, b) => a.numerical_value - b.numerical_value);
      this.loading = false;
    },
    async createPassage() {
      if (this.datePassage === null || this.statutPassage === null) {
        this.$store.dispatch("annonce/annonceWarning", "Veuillez remplir les champs statut et date correctement.");
      }
      let passage = this.passages.find((passage) => passage.statut === this.statutPassage);
      if (passage === undefined) {
        await this.postPassage();
      } else {
        await this.updatePassage(passage);
      }
      await this.loadPassages();
      this.statutPassage = null;
      this.datePassage = null;
    },
    async postPassage() {
      let passageObject = {
        statut: this.statutPassage,
        date: this.datePassage,
        dossier: this.selected_dossier.id,
      };
      let result = await this.repository.post(passageObject);
      if (result) {
        this.$store.dispatch("annonce/annonceSuccess", "Date de passage créé");
        let message = `La date de passage au statut "${result.statut_name}" daté au ${this.getFormattedDate(result.date)}`
            + `a été créée.`;
        this.$store.dispatch("dossier/commentaires/postCommentaire", {dossier: this.selected_dossier.id, message})
      }
    },
    async updatePassage(passage) {
      let payload = {
          id: passage.id,
          statut: this.statutPassage,
          date: this.datePassage,
          dossier: this.selected_dossier.id,
        };
        let result = await this.repository.update(payload);
        if (result !== undefined) {
          let newDate = this.getFormattedDate(result.date);
          let message = `La date de passage au statut "${result.statut_name}" daté au ${newDate}`
              + ` a été mis à jour (anciennement : ${this.getFormattedDate(passage.date)}).`;
          this.$store.dispatch("dossier/commentaires/postCommentaire", {dossier: this.selected_dossier.id, message})
          this.$store.dispatch("annonce/annonceSuccess", "Date de passage mis à jour");
        }
    },
    async deletePassage(passageId) {
      let passage = this.passages.find((passageItem) => passageItem.id === passageId);
      let message = `La date de passage au statut "${passage.statut_name}" daté au ${this.getFormattedDate(passage.date)}`
          + `a été supprimé`;
      this.$store.dispatch("dossier/commentaires/postCommentaire", {dossier: this.selected_dossier.id, message})
      await this.repository.delete({id: passageId});
      await this.loadPassages();
    },

  }

}
</script>
<style>
.timeline > ol > li:last-child {
  display: none !important;
}
</style>