<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-bind="attrs" v-on="on" :class="'white--text mt-2 '+ editColor">
        <v-icon>mdi-cog</v-icon>
        {{editText}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" class="white--text">
        <v-toolbar-title>Modifier le dossier</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select v-if="type === 'instructeur'" label="Instructeur" v-model="dossierModel.instructeur"
                  :items="instructeurs" item-text="fullname"
                  item-value="id"></v-select>
        <span v-if="userLoadingStatus !== 'LOADED' && type === 'instructeur'" class="body-2">Chargement des instructeurs en cours</span>
        <v-select v-if="type === 'enqueteur'" label="Enquêteur" v-model="dossierModel.enqueteur" item-text="value" item-value="value"  :items="enqueteurs"></v-select>
        <v-select v-if="type === 'maitre_oeuvre'" label="Maître d'oeuvre" v-model="dossierModel.maitre_oeuvre" :items="maitresOeuvre"
                  item-text="fullname"
                  item-value="id"></v-select>
        <v-select v-if="type === 'conducteur_travaux'" label="Conducteur de travaux" v-model="dossierModel.conducteur_travaux" :items="conducteursTravaux"
                  item-text="fullname"
                  item-value="id"></v-select>
        <span v-if="userLoadingStatus !== 'LOADED' && type === 'enqueteur'" class="body-2">Chargement des enquêteurs en cours</span>
        <v-select v-if="type === 'statut'" label="Etat du dossier" v-model="dossierModel.statut" :items="statuts"
                  item-text="name"
                  item-value="id"></v-select>
        <span v-if="statutFetchStatus !== 'LOADED' && type === 'statut'"
              class="body-2">Chargement des statuts en cours</span>
        <v-text-field v-if="type === 'reference'" label="Référence" v-model="dossierModel.reference"
                  ></v-text-field>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="patchDossier" class="mb-2" :disabled="editing" :color="editing ? 'grey':'primary'">Modifier le dossier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {DossierEtatMixin} from "@/mixins/DossierEtatMixin";
const HISTORY_MESSAGE_BY_TYPE = {
  "instructeur": (old, newDossier) => `Nouveau instructeur ajouté au dossier : ${newDossier.instructeur.fullname}
   (Anciennement : ${old?.instructeur?.fullname || "Aucun"})`,
  "enqueteur": (old, newDossier) => `Nouvel enquêteur associé au dossier : ${newDossier.enqueteur}
  (Anciennement : ${old?.enqueteur || "Aucun"})`,
  "statut": (old, newDossier) => `Statut du dossier mis à jour : "${newDossier?.statut?.name}"
   (Anciennement : "${old?.statut?.name || "Aucun"}")`,
  "reference": (old, newDossier) => `Référence du dossier mis à jour : "${newDossier?.reference}"
   (Anciennement : "${old?.reference || "Aucun"}")`,
  "maitre_oeuvre": (old, newDossier) => `Maitre d'oeuvre du dossier mis à jour : "${newDossier?.maitre_oeuvre.fullname}"
   (Anciennement : "${old?.maitre_oeuvre.fullname || "Aucun"}")`,
  "conducteur_travaux": (old, newDossier) => `Conducteur de travaux du dossier mis à jour : "${newDossier?.conducteur_travaux.fullname}"
   (Anciennement : "${old?.conducteur_travaux.fullname || "Aucun"}")`,
}
export default {
  name: 'DossierEditForm',
  props: {
    dossier: Object,
    type: String,
    editText: String,
    editColor: String,
  },
  mixins: [DossierEtatMixin],
  async mounted() {
    this.cleanFields();
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    let userFetchStatus = this.$store.getters["users/loading_status"];
    if (userFetchStatus === "INITIAL" || userFetchStatus === "ERROR") {
      await this.$store.dispatch("users/fetchUsers");
    }
  },
  data() {
    let dossierModel = JSON.parse(JSON.stringify(this.dossier));
    return {
      dossierModel: dossierModel,
      dialog: false,
      editing: false,
    }
  },
  computed: {
    instructeurs() {
      return this.$store.getters["users/instructeurs"];
    },
    enqueteurs() {
      return this.$store.getters["select/enqueteurs"];
    },
    maitresOeuvre() {
      return this.$store.getters["users/maitres_oeuvre"];
    },
    conducteursTravaux() {
      return this.$store.getters["users/conducteur_travaux"];
    },
    userLoadingStatus() {
      return this.$store.getters["users/loading_status"];
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    statutFetchStatus() {
      return this.$store.getters["dossier/statuts/statut_fetch_status"];
    }
  },
  methods: {
    async patchDossier() {
      this.editing = true;
      this.cleanFields();
      delete this.dossierModel.bien_immobilier;
      delete this.dossierModel.honoraires;
      delete this.dossierModel.plan_financement;
      let oldDossier = JSON.parse(JSON.stringify(this.dossier));
      let result = await this.$store.dispatch("dossier/patchDossier", this.dossierModel);
      let newDossier = this.$store.getters["dossier/getSelectedDossier"];
      this.dialog = false;
      this.editing = false;
      if(result === "success") {
        let dossier_id = this.dossier.id;
        let historyEntry = {
          dossier: dossier_id,
          message: HISTORY_MESSAGE_BY_TYPE[this.type](oldDossier, newDossier),
        }
        await this.$store.dispatch("dossier/commentaires/postCommentaire", historyEntry);
        this.$root.$emit("reload-comments");
        await this.$store.dispatch("annonce/annonceSuccess","Dossier mis à jour.",{root: true});
      }


    },
    cleanFields() {
      if (typeof this.dossierModel?.instructeur === "object" && this.dossierModel?.instructeur !== null) {
        this.dossierModel.instructeur = this.dossierModel.instructeur.id;
      }
      if (typeof this.dossierModel?.statut === "object" && this.dossierModel?.statut !== null) {
        this.dossierModel.statut = this.dossierModel?.statut?.id;
      }
      if (typeof this.dossierModel?.conducteur_travaux === "object" && this.dossierModel?.conducteur_travaux !== null) {
        this.dossierModel.conducteur_travaux = this.dossierModel?.conducteur_travaux?.id;
      }
      if (typeof this.dossierModel?.maitre_oeuvre === "object" && this.dossierModel?.maitre_oeuvre !== null) {
        this.dossierModel.maitre_oeuvre = this.dossierModel?.maitre_oeuvre?.id;
      }
    }
  }
}
</script>