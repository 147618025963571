import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('h2',{staticClass:"primary--text"},[_vm._v("Informations complémentaires")]),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VSimpleTable,[[_c('thead',[_c('tr',[_c('th',[_vm._v("Statut")]),_c('th',[_vm._v("Date")]),_c('th')])]),_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)]):_vm._e(),_vm._l((_vm.passages),function(passage){return _c('tr',{key:passage.id},[_c('td',[_vm._v(_vm._s(passage.statut_name))]),_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(passage.date)))]),_c('td',[_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deletePassage(passage.id)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',{staticClass:"red--text"},[_vm._v("Supprimer")])])],1)])}),_c('tr',[_c('td',[_c(VSelect,{attrs:{"label":"Statut","items":_vm.statuts,"item-value":"id","item-text":"name"},model:{value:(_vm.statutPassage),callback:function ($$v) {_vm.statutPassage=$$v},expression:"statutPassage"}})],1),_c('td',[_c(VMenu,{staticClass:"ma-0 pa-0",attrs:{"max-width":"100%","close-on-content-click":false,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date","value":_vm.getFormattedDate(_vm.datePassage) || ''}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_date_passage),callback:function ($$v) {_vm.menu_date_passage=$$v},expression:"menu_date_passage"}},[_c(VCard,[_c(VDatePicker,{model:{value:(_vm.datePassage),callback:function ($$v) {_vm.datePassage=$$v},expression:"datePassage"}}),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu_date_passage = false}}},[_vm._v("Fermer")])],1)],1)],1)],1),_c('td',[_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":_vm.createPassage}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"primary--text"},[_vm._v("Créer")])])],1)])],2)]],2)],1),_c(VCol,{attrs:{"cols":"6"}},[_c('InstructeurDEAL',{attrs:{"selected_dossier":_vm.selected_dossier}}),_c('NumeroArrete',{attrs:{"selected_dossier":_vm.selected_dossier}}),_c('NumeroArreteCTM',{attrs:{"selected_dossier":_vm.selected_dossier}}),_c('DateArrete',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('AnneeArrete',{attrs:{"selected_dossier":_vm.selected_dossier}}),_c('DatePaiementEnqueteur',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('RetourDEAL',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('ControleurDEAL',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('EtudeTechnique',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('ConducteurTravaux',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('StatutPret',{attrs:{"selected-dossier":_vm.selected_dossier}}),_c('NumeroBPI',{attrs:{"selected-dossier":_vm.selected_dossier}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }