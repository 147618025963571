import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.startSignatureProcess}},'v-btn',attrs,false),on),[(!_vm.hasSignature)?_c('span',[_vm._v(_vm._s(_vm.buttonText))]):_c('span',[_vm._v(_vm._s(_vm.signedButtonText))])])]}}]),model:{value:(_vm.signingDialog),callback:function ($$v) {_vm.signingDialog=$$v},expression:"signingDialog"}},[_c(VCard,[_c(VAppBar,{attrs:{"color":"primary"}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Signer directement")])],1),_c(VRow,{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('canvas',{ref:"signatureCanvas",staticClass:"mt-4 grey-border",attrs:{"id":"signatureCanvas","height":_vm.height,"width":_vm.width}})]),_c(VCardActions,[_c(VBtn,{staticClass:"white--text",attrs:{"color":"grey"},on:{"click":_vm.clearSigningPad}},[_vm._v("Nettoyer")]),_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.validateSignature}},[_vm._v("Signer")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }